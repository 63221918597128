import type React from 'react';
import { createContext } from 'react';

export interface ContextInterface {
    loginExpires: Date | undefined;
    setLoginExpires: React.Dispatch<React.SetStateAction<Date | undefined>>;
    darkThemeEnabled: boolean;
    setDarkThemeEnabled: (setting: boolean) => void;
    permissionLevel: string;
    setPermissionLevel: React.Dispatch<React.SetStateAction<string>>;
    verifiedLogin: boolean;
    setVerifiedLogin: React.Dispatch<React.SetStateAction<boolean>>;
    referralCode: string;
    setReferralCode: React.Dispatch<React.SetStateAction<string>>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    logUserEvent: Function;
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApplicationContext = createContext<ContextInterface>({
    loginExpires: undefined,
    setLoginExpires: () => {
        return;
    },
    darkThemeEnabled: false,
    setDarkThemeEnabled: () => {
        return;
    },
    permissionLevel: '',
    setPermissionLevel: () => {
        return;
    },
    verifiedLogin: false,
    setVerifiedLogin: () => {
        return;
    },
    referralCode: '',
    setReferralCode: () => {
        return;
    },
    logUserEvent: () => {
        return;
    },
    sidebarOpen: false,
    setSidebarOpen: () => {
        return;
    }
});

export default ApplicationContext;
