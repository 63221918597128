import CssBaseline from '@mui/material/CssBaseline';
import Cookies from 'react-cookie/cjs/Cookies';
import { useQuery } from 'react-query';
import { useContext, useState } from 'react';
import Main from './Main';
import ApplicationContext from './ContextProvider';
import Sidebar from './components/Sidebar';
import Loading from './components/Loading';
import { logout } from './utilities/Auth';

export default function App(): JSX.Element {
    const context = useContext(ApplicationContext);
    const [appLoading, setAppLoading] = useState<boolean>(true);

    useQuery('verifyLogin', async () => {
        const cookies: Cookies = new Cookies();
        const cpebl: string = cookies.get('cpebl') ?? '';

        // On application initialization look for cookie and send to back end for verification and to retrieve permission level
        if (cpebl.length > 0 && !context.verifiedLogin) {
            await (async () => {
                const loginResult: Response = await fetch('/users/verify', {
                    method: 'POST',
                    body: JSON.stringify({ cookie: cpebl }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const responseJson = await loginResult.json();
                if (loginResult.ok) {
                    context.setPermissionLevel(responseJson.data.permission_level);
                    context.setReferralCode(responseJson.data.referral_code);
                    context.setVerifiedLogin(true);
                } else {
                    logout(context);
                }
                setAppLoading(false);
            })();
        } else {
            setAppLoading(false);
        }
    });

    return appLoading ? (
        <Loading />
    ) : (
        <>
            <CssBaseline />
            <Sidebar />
            <Main />
        </>
    );
}
