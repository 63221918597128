import {
    Typography,
    Button,
    Grid,
    Card,
    CardHeader,
    CardActionArea,
    CardMedia,
    CardContent,
    List,
    ListItem
} from '@mui/material';
import { useContext } from 'react';
import ApplicationContext from '../ContextProvider';

const ReferralCodeDetails = () => {
    const context = useContext(ApplicationContext);

    return (
        <Grid container width={'100%'} justifyContent={'center'} padding={'15px'}>
            <Grid item>
                <Card variant="outlined">
                    <CardHeader title="Welcome to to the CPE Online Academy Referral Program"></CardHeader>
                    <CardMedia
                        sx={{ minHeight: '600px' }}
                        image="/images/refer_your_friends.jpeg"
                        title="Refer Your Friends Image"
                    ></CardMedia>
                    <CardContent>
                        <CardActionArea>
                            <Button
                                sx={{ width: '95%', whiteSpace: 'normal', height: 'auto' }}
                                variant="contained"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `Hi your friend would like to refer you to the CPE Online Academy, their unique referral code is: ${context.referralCode}`
                                    );
                                }}
                            >
                                Want to refer a friend? Click here to copy your unique referral code and invitation
                                message!
                            </Button>
                        </CardActionArea>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item padding={'2rem'}>
                <Typography variant="h5">Details of the CPE Online Academy Referral Program:</Typography>
                <Typography alignContent={'center'} padding={'2rem'} variant="body2" maxWidth={'75vw'}>
                    We have set out to make the CPE Online Academy <em>the</em> place to prepare for the CPE exam. As a
                    part of this we wanted to reward our students and their friends and colleagues. If you have a friend
                    or colleague who is in the process of studying for their CPE exam we created a referral system that
                    benefits you in your studies as well as the person you are referring. Below we have listed the
                    conditions and benefits of the referral system.
                    <List dense sx={{ listStyleType: 'circle', listStylePosition: 'inside', textAlign: 'center' }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            A referral indicates any person who supplies a referral code at the time of their
                            registration with the CPE Online Academy
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            The bonuses awarded to each the referrer and the referred is 1 week of access. This benefit
                            can be claimed at any time after it has been awarded.
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>Referral bonuses are non-transferrable.</ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            In order to claim your bonus you must contact the{' '}
                            <a href="mail:management@cpeonlineacademy.com">CPE Online Academy</a> indicating your intent
                            to make use of an acquired referral bonus.
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            Each bonus awarded for each referral may only be claimed once.
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            The specifics of the bonus may be modified at any time by CPE Online Academy, without notice
                            to the bonus holder
                        </ListItem>
                    </List>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ReferralCodeDetails;
