import { useContext, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilePresentTwoTone from '@mui/icons-material/FilePresentTwoTone';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import ApplicationContext from '../../ContextProvider';
import FileViewer from '../FileBrowser/FileViewer';
import '../FileBrowser/css/FileViewer.css';
import VideoPlayer from '../VideoPlayer';
import { type ClassroomFileBrowserProps, type ContentFile } from '../../interfaces/ClassroomInterfaces';
import React from 'react';

const ClassroomFileBrowser = (classroomFileBrowserProps: ClassroomFileBrowserProps) => {
    const classroomFiles = Object.values(classroomFileBrowserProps.classroomFiles);
    const theme = useTheme();
    const context = useContext(ApplicationContext);
    const [fileStack, setFileStack] = useState<Array<ContentFile>>([]);
    const [filePath, setFilePath] = useState<string>('');

    useMemo(() => {
        setFileStack(classroomFiles);
        setFilePath('');
    }, classroomFiles);

    return (
        <Grid
            container
            height={'100%'}
            width={'100%'}
            style={{ marginTop: '15px' }}
            onContextMenu={(event) => {
                event.preventDefault();
                event.stopPropagation();
                return;
            }}
        >
            <Grid item xs={12} sm={12}>
                <Grid
                    container
                    spacing={1}
                    width={'100%'}
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    sx={{
                        backgroundColor: context.darkThemeEnabled ? theme.palette.grey[700] : theme.palette.grey[200],
                        pb: '1rem',
                        paddingTop: '5px'
                    }}
                >
                    {Object.values(fileStack).map((file) => (
                        <Grid
                            key={file.id}
                            item
                            width={filePath && filePath.length > 0 ? '100%' : '50%'}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Button
                                type={'button'}
                                sx={{
                                    padding: 0,
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                                onClick={async () => {
                                    const urlRequest = await fetch(`/file/${file.file_prefix}/${file.file_key}`, {
                                        method: 'GET'
                                    });
                                    if (urlRequest.ok) {
                                        const signedUrl = (await urlRequest.json()).data.signedUrl;
                                        setFilePath(signedUrl);
                                    }
                                }}
                            >
                                <Paper
                                    elevation={6}
                                    sx={{
                                        marginX: '5px',
                                        paddingY: '8px',
                                        paddingX: '12px',
                                        width: '100%'
                                    }}
                                >
                                    <FilePresentTwoTone sx={{ marginBottom: '-5px' }} />
                                    {file.file_name}
                                </Paper>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {filePath && filePath.length > 0 && (
                <Grid item height={'95vh'} xs={12} sm={12}>
                    <Grid
                        item
                        height={'100%'}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        {filePath.toLowerCase().includes('.mp4') ? (
                            <VideoPlayer videoUrl={filePath} />
                        ) : (
                            <FileViewer filePath={filePath} />
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(ClassroomFileBrowser);
