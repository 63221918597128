import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FileViewer = (props: any) => {
    return (
        <DocViewer
            documents={[{ uri: props.filePath }]}
            pluginRenderers={DocViewerRenderers}
            config={{
                pdfZoom: { defaultZoom: 1, zoomJump: 1 },
                pdfVerticalScrollByDefault: true,
                header: { disableHeader: true }
            }}
            prefetchMethod="GET"
        />
    );
};

export default React.memo(FileViewer);
