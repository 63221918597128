import { PausePresentation, Fullscreen } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { CardMedia, Box, Stack, Slider, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import './css/VideoPlayer.css';

const VideoPlayer = (props: { videoUrl: string }) => {
    const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
    const [videoLoading, setVideoLoading] = useState<boolean>(true);
    const [seekTime, setSeekTime] = useState<number>(-1);
    const [minSeek, setMinSeekTime] = useState<number>(0);
    const [maxSeek, setMaxSeekTime] = useState<number>(1);
    const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(false);
    const videoContent: HTMLElement = document.getElementById('video_content') as HTMLElement;
    let videoElement: HTMLVideoElement = document.getElementById('video_element') as HTMLVideoElement;

    useEffect(() => {
        setVideoLoading(true);
        if (props.videoUrl.length > 0 && videoElement) {
            if (fullscreenEnabled) {
                document.exitFullscreen();
                setFullscreenEnabled(false);
            }
            videoElement.currentTime = 0;
            videoElement.load();
            setVideoPlaying(false);
            setSeekTime(-1);
        }
    }, [props.videoUrl]);

    const formatVideoTime = (duration: number | string) => {
        let m: number | string = Math.floor((duration as number) / 60);
        m = m >= 10 ? m : '0' + m;
        duration = Math.floor((duration as number) % 60);
        duration = duration >= 10 ? duration : '0' + duration;
        return m + ':' + duration;
    };

    const startVideo = async () => {
        if (videoElement.currentTime !== seekTime) {
            videoElement.currentTime = seekTime;
        }
        setVideoPlaying(true);
        videoElement.play();
    };

    const pauseVideo = async () => {
        setVideoPlaying(false);
        videoElement.pause();
    };

    const toggleFullscreen = () => {
        if (!fullscreenEnabled && videoContent.requestFullscreen) {
            videoContent.requestFullscreen();
            setFullscreenEnabled(true);
        } else if (fullscreenEnabled) {
            if (document.fullscreenElement) {
                document.exitFullscreen();
                setFullscreenEnabled(false);
            } else {
                videoContent.requestFullscreen();
            }
        }
    };
    return (
        <CardMedia
            id="video_content"
            component="div"
            className="video-content player"
            sx={{ position: 'relative', display: 'flex' }}
        >
            <video
                id="video_element"
                className={fullscreenEnabled ? 'video-element-fullscreen' : 'video-element'}
                onLoadedData={() => {
                    setVideoLoading(false);
                    videoElement = document.getElementById('video_element') as HTMLVideoElement;
                    setMinSeekTime(videoElement.seekable.start(0));
                    setMaxSeekTime(videoElement.seekable.end(0));
                }}
                onContextMenu={(contextMenu: React.MouseEvent<HTMLVideoElement>) => {
                    contextMenu.preventDefault();
                }}
                onEnded={() => {
                    setVideoPlaying(false);
                }}
                onTimeUpdate={(timeUpdateEvent: React.SyntheticEvent<HTMLVideoElement, Event>) => {
                    const timeUpdatelement = timeUpdateEvent.target as HTMLVideoElement;
                    setSeekTime(timeUpdatelement.currentTime);
                }}
                style={{ minHeight: '1px', minWidth: '1px' }}
            >
                <source key={props.videoUrl} src={props.videoUrl ?? ''} />
            </video>
            <Box
                className={videoPlaying ? 'controls' : ''}
                style={{ position: 'absolute', bottom: '5%', width: '100%', background: 'rgba(0, 0, 0, 0.3)' }}
            >
                {!videoLoading && (
                    <>
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{ mt: 1, width: '90%', left: '5%', position: 'relative' }}
                            alignItems="center"
                        >
                            <Slider
                                aria-label="time seek slider"
                                className={'video-slider'}
                                min={minSeek}
                                max={maxSeek}
                                value={seekTime}
                                onChangeCommitted={(
                                    seekEvent: Event | React.SyntheticEvent<Element, Event>,
                                    seekValue: number | Array<number>
                                ) => {
                                    setSeekTime(Number(seekValue));
                                    videoElement.currentTime = Number(seekValue);
                                }}
                            />
                        </Stack>
                        <IconButton
                            disabled={videoLoading}
                            aria-label={videoPlaying ? 'pause' : 'play'}
                            onClick={() => {
                                videoPlaying ? pauseVideo() : startVideo();
                            }}
                            sx={{ left: '45%' }}
                            color="primary"
                        >
                            {videoPlaying ? (
                                <PausePresentation sx={{ height: 38, width: 38 }} />
                            ) : (
                                <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                            )}
                        </IconButton>
                        <IconButton
                            disabled={videoLoading}
                            aria-label={'fullscreen toggle'}
                            onClick={() => {
                                toggleFullscreen();
                            }}
                            sx={{ left: '45%' }}
                            color="primary"
                        >
                            {<Fullscreen sx={{ height: 38, width: 38 }} />}
                        </IconButton>
                        <IconButton disabled={true} aria-label={'time display'} sx={{ left: 0 }} color="primary">
                            {videoElement && videoElement.currentTime
                                ? formatVideoTime(videoElement.currentTime)
                                : '00:00'}
                        </IconButton>
                        <IconButton disabled={true} aria-label={'time display'} sx={{ left: '40%' }} color="primary">
                            {videoElement && videoElement.currentTime
                                ? formatVideoTime(videoElement.duration)
                                : '00:00'}
                        </IconButton>
                    </>
                )}
            </Box>
        </CardMedia>
    );
};

export default VideoPlayer;
