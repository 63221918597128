import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const Links = () => {
    const generalLinks = [
        {
            title: 'Finding Hindlimb Lameness',
            link: 'https://www.youtube.com/watch?v=O-_UFkDiq0w',
            img: 'https://i.ytimg.com/vi/O-_UFkDiq0w/mqdefault.jpg'
        },
        {
            title: 'Finding Forelimb Lameness',
            link: 'https://www.youtube.com/watch?v=F8103_4Rq2k',
            img: 'https://i.ytimg.com/vi/F8103_4Rq2k/mqdefault.jpg'
        },
        {
            title: 'Picking Up Horse Feet',
            link: 'https://www.youtube.com/watch?v=bvuHXad-2wc',
            img: 'https://i.ytimg.com/vi/bvuHXad-2wc/mqdefault.jpg'
        },
        {
            title: 'Approaching and Catching Horses',
            link: 'https://www.youtube.com/watch?v=0CABQdO1XAg',
            img: 'https://i.ytimg.com/vi/0CABQdO1XAg/mqdefault.jpg'
        },
        {
            title: 'Wrapping Horse Legs',
            link: 'https://www.youtube.com/watch?v=GicfRy-70V4',
            img: 'https://i.ytimg.com/vi/GicfRy-70V4/mqdefault.jpg'
        },
        {
            title: 'Equine Lameness Exam',
            link: 'https://www.youtube.com/watch?v=A1gisjYuuzY',
            img: 'https://i.ytimg.com/vi/A1gisjYuuzY/mqdefault.jpg'
        },
        {
            title: 'Udder Preparation',
            link: 'https://www.youtube.com/watch?v=8xXvruUFEl0',
            img: 'https://i.ytimg.com/vi/8xXvruUFEl0/mqdefault.jpg'
        },
        {
            title: 'Bovine Mastitis Test',
            link: 'https://www.youtube.com/watch?v=vXSW3xVy8Sc&t=217s',
            img: 'https://i.ytimg.com/vi/vXSW3xVy8Sc/mqdefault.jpg'
        },
        {
            title: 'Collecting Bovine Milk',
            link: 'https://www.youtube.com/watch?v=on0SrRkDFtk',
            img: 'https://i.ytimg.com/vi/on0SrRkDFtk/mqdefault.jpg'
        },
        {
            title: 'Clinical Bovine Exam',
            link: 'https://www.youtube.com/watch?v=FaZcJHuDvlk',
            img: 'https://i.ytimg.com/vi/FaZcJHuDvlk/mqdefault.jpg'
        },
        {
            title: 'Small Animal Radiology',
            link: 'https://www.youtube.com/watch?v=glFIEbKKAho',
            img: 'https://i.ytimg.com/vi/glFIEbKKAho/mqdefault.jpg'
        },
        {
            title: 'Small Animal Necropsy',
            link: 'https://www.youtube.com/watch?v=PUuves6t2p8',
            img: 'https://i.ytimg.com/vi/PUuves6t2p8/mqdefault.jpg'
        },
        {
            title: 'Monitoring Vital Signs',
            link: 'https://www.youtube.com/watch?v=anfYTeEj1-Y',
            img: 'https://cf-images.eu-west-1.prod.boltdns.net/v1/static/1079060364/b5362249-e497-4f17-8709-f39e55c05e3f/5856f6fd-1e87-40e5-99a6-c5e6a441924c/640x360/match/image.jpg'
        },
        {
            title: 'SAM Anesthesia',
            link: 'https://www.youtube.com/watch?v=NMlsSvps75s',
            img: 'https://cf-images.eu-west-1.prod.boltdns.net/v1/static/1079060364/6ba7cf0a-fa29-48ce-95c7-f94df0668a78/95597e95-c89f-41b1-9269-06d022617bf8/640x360/match/image.jpg'
        },
        {
            title: 'Virtual Oquendo Campus',
            link: 'https://my.matterport.com/show/?m=2JyY1gXdYWc',
            img: 'https://my.matterport.com/api/v1/player/models/2JyY1gXdYWc/thumb?width=1684&dpr=1&disable=upscale'
        },
        {
            title: 'Aging Horses',
            link: 'http://www.vivo.colostate.edu/hbooks/pathphys/digestion/pregastric/aginghorses.html',
            img: 'http://www.vivo.colostate.edu/hbooks/pathphys/digestion/pregastric/spotstarb.jpg'
        },
        {
            title: 'Lamness Trainer',
            link: 'https://www.lamenesstrainer.com',
            img: 'https://www.lamenesstrainer.com/wp-content/uploads/2020/05/Mod01MaskedAI_small.png'
        },
        {
            title: 'Checking Bovine Pregnancy',
            link: 'http://therio.vetmed.lsu.edu/bovine_pregnancy.htm',
            img: 'http://therio.vetmed.lsu.edu/bovine108.gif'
        },
        {
            title: 'Bovine Diseases',
            link: 'https://www.thecattlesite.com/diseaseinfo/',
            img: 'https://cdn.globalagmedia.com/Pagebuilder/CattleIcon.svg'
        },
        {
            title: 'Ruminant Diseases',
            link: 'http://www.sheep101.info/201/diseasesa-z.html',
            img: 'http://www.sheep101.info/201/Images/sheep201newlogo..jpg'
        },
        {
            title: 'SAM Joint Radiography',
            link: 'https://www.saintfrancis.org/wp-content/uploads/Radiograph-Stifle-Vet-Practice-2012.pdf',
            img: 'https://todaysveterinarypractice.com/wp-content/uploads/sites/4/2012/03/Berry_TVPMarApr2012_Stifle-Joint-and-Crus-Radiography.png'
        }
    ];

    return (
        <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h4" sx={{ mx: 1, pt: 1 }}>
                Helpful Links from Dr. Thompson
            </Typography>
            <Typography variant="caption" sx={{ mx: 1, my: 0 }}>
                All content within these links owned by the original author
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
            >
                {generalLinks.map((link) => {
                    return (
                        <Link
                            key={link.link}
                            sx={{
                                m: 1,
                                backgroundImage: `url(${link.img})`,
                                backgroundSize: 'cover',
                                borderRadius: 1.5,
                                textDecoration: 'none'
                            }}
                            href={link.link}
                            target="_blank"
                        >
                            <Paper
                                elevation={4}
                                sx={{
                                    width: '128px',
                                    height: '50px',
                                    marginTop: '85px',
                                    overflow: 'hidden',
                                    textAlign: 'center'
                                }}
                            >
                                {link.title}
                            </Paper>
                        </Link>
                    );
                })}
            </Box>
        </Paper>
    );
};

export default Links;
