import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';

const Loading = () => {
    return (
        <>
            <CssBaseline />
            <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', height: '110px', width: '110px' }} />
        </>
    );
};

export default Loading;
