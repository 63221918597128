import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Close from '@mui/icons-material/Close';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ApplicationContext from '../ContextProvider';

const PasswordReset = () => {
    const context = useContext(ApplicationContext);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordVerify, setShowPasswordVerify] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [passwordVerify, setPasswordVerify] = useState<string>('');
    const [passwordError, setPasswordError] = useState<Array<string>>([]);
    const [passwordVerifyError, setPasswordVerifyError] = useState<Array<string>>([]);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [popover, setPopover] = useState<string>('');
    const [resetPassword, setResetPassword] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
        const token: string | null = queryParams.get('token');

        if (!token || token.length <= 0) {
            navigate('/');
        }
        setResetPassword(queryParams.get('token') ?? '');
    }, []);

    const validateEmail = (email: string): void => {
        if (email.length > 0) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return setEmailError(!emailRegex.test(email));
        }

        return setEmailError(false);
    };

    const verifyPasswordFields = (): boolean => {
        const passwordErrors: Array<string> = [];
        const passwordVerifyErrors: Array<string> = [];

        if (password !== passwordVerify) {
            passwordErrors.push('The passwords supplied did not match');
            passwordVerifyErrors.push('The passwords supplied did not match');
        }

        if (password.search(/[a-z]/) < 0) {
            passwordErrors.push('Your password must contain at least one lowercase letter');
        }

        if (passwordVerify.search(/[a-z]/) < 0) {
            passwordVerifyErrors.push('Your password must contain at least one lowercase letter');
        }

        if (password.search(/[A-Z]/) < 0) {
            passwordErrors.push('Your password must contain at least one uppercase letter');
        }

        if (passwordVerify.search(/[A-Z]/) < 0) {
            passwordVerifyErrors.push('Your password must contain at least one uppercase letter');
        }

        if (password.search(/[0-9]/) < 0) {
            passwordErrors.push('Your password must contain at least one number');
        }

        if (passwordVerify.search(/[0-9]/) < 0) {
            passwordVerifyErrors.push('Your password must contain at least one number');
        }

        if (password.search(/[!@#$%^&*()]/) < 0) {
            passwordErrors.push('Your password must contain at least one special character[!@#$%^&*()]');
        }

        if (passwordVerify.search(/[!@#$%^&*()]/) < 0) {
            passwordVerifyErrors.push('Your password must contain at least one special character[!@#$%^&*()]');
        }

        if (password.length < 6) {
            passwordErrors.push('Your password must contain at least 6 characters');
        }

        if (passwordVerify.length < 6) {
            passwordVerifyErrors.push('Your password must contain at least 6 characters');
        }

        setPasswordError(passwordErrors);
        setPasswordVerifyError(passwordVerifyErrors);

        if (passwordErrors.length > 0 || passwordVerifyErrors.length > 0) {
            return false;
        }

        return true;
    };

    const updatePopover = (popoverContent: string): void => {
        // Reset our popover after 5 seconds if it isn't manually closed
        setTimeout(() => {
            setPopover('');
        }, 5000);
        return setPopover(popoverContent);
    };

    const login = async (form: React.FormEvent): Promise<void> => {
        form.preventDefault();

        if (!verifyPasswordFields()) {
            return;
        }

        const loginResult = await fetch('/users/password_reset', {
            method: 'POST',
            body: JSON.stringify({ email, password, password_verify: passwordVerify, token: resetPassword }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (loginResult.ok) {
            // Show our popover to designate that user was successfully logged in
            updatePopover(`Thanks for signing in, welcome to the application!`);
            // Reset our controlled inputs to clear the form
            setPassword('');
            setEmail('');
            navigate('/');
        } else {
            const responseJson = await loginResult.json();
            updatePopover(responseJson.message);
        }
    };

    function generateListElements(listElements: Array<string>) {
        return listElements.map((elementValue, index) => (
            <ListItem key={index}>
                <ListItemText primary={elementValue} secondary={null} />
            </ListItem>
        ));
    }

    return (
        <>
            <form onSubmit={login}>
                <Grid container alignItems="center" justifyContent="center" direction="row">
                    <Grid item width={'50vw'}>
                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <TextField
                                id="email"
                                type={'text'}
                                required
                                helperText={emailError ? 'Invalid email supplied' : 'Example: John@gmail.com'}
                                error={emailError}
                                value={email}
                                onChange={(emailElem) => {
                                    setEmail(emailElem.target.value);
                                }}
                                onBlur={() => {
                                    validateEmail(email);
                                }}
                                variant={'outlined'}
                                label="Email"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item width={'50vw'}>
                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <TextField
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                helperText={
                                    passwordError.length > 0 ? (
                                        <List dense>{generateListElements(passwordError)}</List>
                                    ) : (
                                        'Password requirements: More than 6 letters, must contain an uppercase letter, lowercase letter, symbol, and number'
                                    )
                                }
                                error={passwordError.length > 0 ?? false}
                                onChange={(password) => {
                                    setPassword(password.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                                onMouseDown={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                label="Password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item width={'50vw'}>
                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                            <TextField
                                id="password_verify"
                                type={showPasswordVerify ? 'text' : 'password'}
                                value={passwordVerify}
                                helperText={
                                    passwordVerifyError.length > 0 ? (
                                        <List dense>{generateListElements(passwordVerifyError)}</List>
                                    ) : (
                                        'Password requirements: More than 6 letters, must contain an uppercase letter, lowercase letter, symbol, and number'
                                    )
                                }
                                error={passwordVerifyError.length > 0 ?? false}
                                onChange={(passwordVerify) => {
                                    setPasswordVerify(passwordVerify.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password verify visibility"
                                                onClick={() => {
                                                    setShowPasswordVerify(!showPasswordVerify);
                                                }}
                                                onMouseDown={() => {
                                                    setShowPasswordVerify(!showPasswordVerify);
                                                }}
                                                edge="end"
                                            >
                                                {showPasswordVerify ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                label="Verify Password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item width={'50vw'}>
                        <Button id="reset_password_button" variant={'outlined'} type={'submit'} sx={{ ml: '50%' }}>
                            Reset Password
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Popover
                id="informational_modal"
                disableAutoFocus
                open={popover.length > 1}
                anchorEl={document.getElementById('submit_button')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                style={{ marginTop: '40px' }}
            >
                <Paper style={{ padding: '10px' }}>
                    {popover}
                    <IconButton
                        aria-label="close popover"
                        onClick={() => {
                            setPopover('');
                        }}
                        onMouseDown={() => {
                            setPopover('');
                        }}
                        edge="end"
                        style={{
                            display: popover.length > 1 ? 'inline-flex' : 'none',
                            marginLeft: '5px',
                            marginRight: '5px'
                        }}
                    >
                        <Close />
                    </IconButton>
                </Paper>
            </Popover>
        </>
    );
};

export default PasswordReset;
