import moment from 'moment';
import { Cookies } from 'react-cookie';
import { type ContextInterface } from '../ContextProvider';

export const logout = (context: ContextInterface): void => {
    const cookies: Cookies = new Cookies();

    context.setPermissionLevel('');
    context.setVerifiedLogin(false);
    const expiryDate: Date = moment().subtract(7, 'days').toDate();
    cookies.set('cpebl', '', { path: '/', expires: expiryDate, sameSite: 'strict' });
};
