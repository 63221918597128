import { useEffect, useState } from 'react';
import './css/Classroom.css';
import { useParams } from 'react-router-dom';
import ClassroomContent from '../components/Classroom/ClassroomContent';
import { useQuery } from 'react-query';
import { Content, Section, UserClassRoomTracking } from '../interfaces/ClassroomInterfaces';
import ClassroomHomepage from '../components/Classroom/ClassroomHomepage';
import Loading from '../components/Loading';

const Classroom = () => {
    const { content_section_id, content_title } = useParams();
    const [classroomSections, setClassroomSections] = useState<Array<Section>>([]);
    const [classroomContent, setClassroomContent] = useState<Content>({} as Content);
    const [nextLink, setNextLink] = useState<string>('');
    const [previousLink, setPreviousLink] = useState<string>('');

    const [userClassroomTracking, setUserClassroomTracking] = useState<Array<UserClassRoomTracking>>([]);

    const updateUserProgress = async (video_timestamp: number, completed = false) => {
        const updateResult: Response = await fetch('/classroom/user_progress', {
            method: 'POST',
            body: JSON.stringify({
                section_id: content_section_id,
                content_slug: content_title,
                completed: completed ? true : false,
                video_timestamp: Number(video_timestamp.toFixed(4))
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (updateResult.ok) {
            const responseJson = await updateResult.json();
            setUserClassroomTracking(responseJson.data.user_classroom_tracking);
        }
    };

    useQuery('retreiveClassroomContent', async () => {
        if (classroomSections.length <= 0) {
            // On classroom initialization go get all the sections
            const sectionsResult: Response = await fetch('/classroom/retreive_all_sections', {
                method: 'GET'
            });

            if (sectionsResult.ok) {
                const responseJson = await sectionsResult.json();
                const sections = [];
                for (const sectionKey of Object.keys(responseJson.data.sections)) {
                    const section: Section = responseJson.data.sections[sectionKey];
                    sections.push({
                        id: section.id,
                        section_title: section.section_title,
                        section_description: section.section_description,
                        content: section.content,
                        subsections: section.subsections
                    });
                }
                setClassroomSections(sections);
            } else {
                setClassroomSections([]);
            }
        }
    });

    const updateClassroomContent = (section: Section) => {
        let classroomContentIndex = -1;
        const classroomContent = Object.values(section.content).filter((content, contentIndex) => {
            if (content.content_url_slug === content_title) {
                classroomContentIndex = contentIndex;
                return true;
            }
            return false;
        })[0];

        if (classroomContentIndex !== -1) {
            if (Object.values(section.content)[classroomContentIndex - 1]) {
                const previousContentSlug =
                    Object.values(section.content)[classroomContentIndex - 1].content_url_slug ?? '';
                setPreviousLink(`/classroom/content/${section.id}/${previousContentSlug}`);
            } else {
                setPreviousLink(``);
            }
            if (Object.values(section.content)[classroomContentIndex + 1]) {
                const nextContentSlug =
                    Object.values(section.content)[classroomContentIndex + 1].content_url_slug ?? '';
                setNextLink(`/classroom/content/${section.id}/${nextContentSlug}`);
            } else {
                setNextLink(``);
            }
        }

        setClassroomContent(classroomContent);
        setUserClassroomTracking(
            classroomContent.user_classroom_tracking.filter(
                (trackingData) =>
                    trackingData.classroom_section_id === Number(content_section_id) &&
                    trackingData.classroom_content_slug === classroomContent.content_url_slug
            ) ?? []
        );
    };

    const findSection = (sections: Array<Section>): Section => {
        const sectionsToProcess = sections ?? classroomSections;
        let foundSection;
        // Iterate through each section to see if the id matches the one supplied in the URL
        sectionsToProcess.forEach((section) => {
            // console.log(section.id);
            if (section.id === Number(content_section_id)) {
                foundSection = section;
            }
        });

        if (!foundSection) {
            sectionsToProcess.forEach((section) => {
                console.log(section);
                Object.values(section.subsections).forEach((subsection) => {
                    console.log(subsection.id);

                    if (Number(subsection.id) === Number(content_section_id)) {
                        foundSection = subsection;
                    }
                });
            });
        }
        return foundSection ?? ({} as Section);
    };

    useEffect(() => {
        if (classroomSections && content_title && content_title.length > 0) {
            const section = findSection(classroomSections);
            if (section.id) {
                updateClassroomContent(section);
            }
        }
    }, [classroomSections, content_title]);

    return !classroomSections.length ? (
        <Loading />
    ) : (
        <>
            {content_section_id &&
                content_title &&
                classroomContent &&
                classroomContent.context_video &&
                (classroomSections.length >= 1 ? (
                    <>
                        <ClassroomContent
                            classroomSections={classroomSections}
                            updateUserProgress={updateUserProgress}
                            classroomContent={classroomContent}
                            userClassroomTracking={userClassroomTracking}
                            setClassroomSections={setClassroomSections}
                            setClassroomContent={setClassroomContent}
                            setUserClassroomTracking={setUserClassroomTracking}
                            nextLink={nextLink}
                            previousLink={previousLink}
                        />
                    </>
                ) : (
                    <>
                        <Loading />
                    </>
                ))}
            {!content_section_id && !content_title && (
                <ClassroomHomepage
                    classroomSections={classroomSections}
                    updateUserProgress={updateUserProgress}
                    classroomContent={classroomContent}
                    userClassroomTracking={userClassroomTracking}
                    setClassroomSections={setClassroomSections}
                    setClassroomContent={setClassroomContent}
                    setUserClassroomTracking={setUserClassroomTracking}
                />
            )}
        </>
    );
};

export default Classroom;
