import { useContext, useEffect, useState } from 'react';
import { type ClassroomProps, type Content, type Section } from '../../interfaces/ClassroomInterfaces';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Button, Divider, List, Typography } from '@mui/material';
import ApplicationContext from '../../ContextProvider';

const ClassroomSections = (classroomProps: ClassroomProps): JSX.Element => {
    const { section_id, subsection_id } = useParams();

    const context = useContext(ApplicationContext);
    const [openSection, setOpenSection] = useState<Section>({} as Section);
    const navigate = useNavigate();

    useEffect(() => {
        const section = findSection();
        setOpenSection(section);
    }, [section_id, subsection_id]);

    const findSection = (): Section => {
        const sectionsArray = classroomProps.classroomSections;

        if (subsection_id && subsection_id !== '') {
            const topSection = sectionsArray.filter((section) => {
                return Number(section.id) === Number(section_id);
            })[0];

            const section = Object.values(topSection.subsections).filter((section) => {
                return Number(section.id) === Number(subsection_id);
            })[0];

            return section ?? ({} as Section);
        } else if (section_id && section_id !== '') {
            const section = sectionsArray.filter((section) => {
                return Number(section.id) === Number(section_id);
            })[0];

            return section;
        } else {
            return {} as Section;
        }
    };

    const navigateToContent = (sectionId: number, content: Content): void => {
        classroomProps.setClassroomContent(content);
        classroomProps.setUserClassroomTracking(content.user_classroom_tracking);
        navigate(`/classroom/content/${sectionId}/${content.content_url_slug}`);
    };

    return (
        <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center">
            {!openSection.id ? (
                classroomProps.classroomSections.map((section: Section) => {
                    if (
                        (!section.content || section.content.length === 0) &&
                        (!section.subsections || section.subsections.length === 0)
                    ) {
                        return (
                            <Grid
                                item
                                key={section.section_description}
                                sx={{ paddingTop: '50px', mx: '2px', my: '20px' }}
                            >
                                <Button variant="outlined" disabled>
                                    <Typography>
                                        {section.section_title}
                                        <br />
                                        <sub>
                                            <em>Coming Soon!</em>
                                        </sub>
                                    </Typography>
                                </Button>
                            </Grid>
                        );
                    } else {
                        return (
                            <Grid
                                item
                                key={section.section_description}
                                sx={{ paddingTop: '50px', mx: '2px', my: '20px' }}
                            >
                                <Link style={{ textDecoration: 'none' }} to={`/classroom/${section.id}`}>
                                    <Button variant="outlined">{section.section_title}</Button>
                                </Link>
                            </Grid>
                        );
                    }
                })
            ) : (
                <>
                    <Grid item xs={12} textAlign={'center'}>
                        <Typography variant="h4">{openSection.section_title}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'} spacing={1}>
                        {openSection.content &&
                            Object.values(openSection.content).map((content: Content) => {
                                return (
                                    <Grid item key={content.content_url_slug}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                navigateToContent(openSection.id, content);
                                                context.setSidebarOpen(false);
                                            }}
                                        >
                                            {content.content_title}
                                        </Button>
                                    </Grid>
                                );
                            })}
                        {openSection.subsections &&
                            Object.values(openSection.subsections).map((section: Section) => {
                                return (
                                    <Grid item key={section.section_title}>
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to={`/classroom/${section_id}${
                                                subsection_id ? `/${subsection_id}/${section.id}` : `/${section.id}`
                                            }`}
                                        >
                                            <Button variant="outlined">{section.section_title}</Button>
                                        </Link>
                                    </Grid>
                                );
                            })}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider variant="middle" />
                    </Grid>

                    <Grid item>
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={subsection_id ? `/classroom/${section_id}` : `/classroom`}
                        >
                            <Button>Back</Button>
                        </Link>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default ClassroomSections;
