import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { ClassroomProps } from '../../interfaces/ClassroomInterfaces';
import ClassroomSections from './ClassroomSections';

const ClassroomHomepage = (classroomProps: ClassroomProps) => {
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <Grid item width={'75%'}>
                <Grid container sx={{ mb: '2%' }}>
                    <Grid item width={'100%'} textAlign={'center'}>
                        <Typography variant="h3">Welcome to your classroom</Typography>
                    </Grid>
                    <Grid item width={'100%'} textAlign={'center'}>
                        <Typography variant="h6">
                            Below you will find organized content for each section. We automatically record your
                            progress as you go through the classroom content.
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                <ClassroomSections {...classroomProps} />
            </Grid>
        </Grid>
    );
};

export default ClassroomHomepage;
