import Grid from '@mui/material/Grid';
import FileBrowser from '../components/FileBrowser/FileBrowser';

const Files = () => {
    return (
        <Grid container sx={{ width: '100%' }}>
            <FileBrowser />
        </Grid>
    );
};

export default Files;
