import { useQuery } from 'react-query';
import { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FilePresentTwoTone from '@mui/icons-material/FilePresentTwoTone';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import ApplicationContext from '../../ContextProvider';
import { type File } from '../../interfaces/FileInterfaces';
import Loading from '../Loading';
import FileViewer from './FileViewer';
import './css/FileViewer.css';
import { useNavigate, useParams } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer';

const FileBrowser = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { section_name } = useParams();
    const context = useContext(ApplicationContext);
    const [files, setFiles] = useState<Array<File>>([]);
    const [fileStack, setFileStack] = useState<Array<File>>([]);
    const [parentKey, setParentKey] = useState<string>('');
    const [filePath, setFilePath] = useState<string>('');

    useQuery('retreiveFilesContent', async () => {
        if (!fileStack.length) {
            // On classroom initialization go get all the sections
            const filesResult: Response = await fetch('/files/list_all_files', {
                method: 'GET'
            });

            if (filesResult.ok) {
                const filesResultJson = await filesResult.json();
                setFiles(filesResultJson.data.files);
                if (section_name && Object.keys(files).indexOf(section_name) !== -1) {
                    setFileStack([Object.values(files)[Object.keys(files).indexOf(section_name)]]);
                    setParentKey(section_name);
                } else {
                    setFileStack(filesResultJson.data.files);
                }
            }
        }
    });

    return Object.keys(files).length <= 0 ? (
        <Loading />
    ) : (
        <Grid
            container
            height={'100%'}
            width={'100%'}
            onContextMenu={(event) => {
                event.preventDefault();
                event.stopPropagation();
                return;
            }}
        >
            <Grid item xs={12} sm={filePath && filePath.length > 0 ? 6 : 12}>
                <Grid
                    container
                    spacing={1}
                    width={'100%'}
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    sx={{
                        backgroundColor: context.darkThemeEnabled ? theme.palette.grey[700] : theme.palette.grey[200],
                        pb: '1rem',
                        paddingTop: '5px'
                    }}
                >
                    {parentKey && (
                        <Button
                            type={'button'}
                            sx={{
                                padding: 0,
                                width: '100%',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                setParentKey('');
                                setFileStack(files ?? []);
                                navigate('/files');
                            }}
                        >
                            <Paper
                                elevation={6}
                                sx={{
                                    paddingY: '8px',
                                    width: '100%',
                                    borderRadius: 0
                                }}
                            >
                                Back
                            </Paper>
                        </Button>
                    )}
                    {Object.entries(fileStack).map((folder) =>
                        !folder[1].key ? (
                            <Grid
                                key={folder[0]}
                                item
                                width={filePath && filePath.length > 0 ? '100%' : '50%'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Button
                                    type={'button'}
                                    sx={{
                                        padding: 0,
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                    onClick={() => {
                                        setParentKey(folder[0]);
                                        setFileStack(
                                            Object.values(Object.values(files)[Object.keys(files).indexOf(folder[0])])
                                        );
                                        navigate(`/files/${folder[0]}`);
                                    }}
                                >
                                    <Paper
                                        elevation={6}
                                        sx={{
                                            marginX: '5px',
                                            paddingY: '8px',
                                            paddingX: '12px',
                                            width: '100%'
                                        }}
                                    >
                                        <FilePresentTwoTone sx={{ marginBottom: '-5px' }} />
                                        {folder[0]}
                                    </Paper>
                                </Button>
                            </Grid>
                        ) : (
                            <Grid
                                key={folder[1].key}
                                item
                                width={filePath && filePath.length > 0 ? '100%' : '50%'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Button
                                    type={'button'}
                                    sx={{
                                        padding: 0,
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                    onClick={async () => {
                                        const urlRequest = await fetch(`/file/${folder[1].prefix}/${folder[1].key}`, {
                                            method: 'GET'
                                        });
                                        if (urlRequest.ok) {
                                            const signedUrl = (await urlRequest.json()).data.signedUrl;
                                            setFilePath(signedUrl);
                                        }
                                    }}
                                >
                                    <Paper
                                        elevation={6}
                                        sx={{
                                            marginX: '5px',
                                            paddingY: '8px',
                                            paddingX: '12px',
                                            width: '100%'
                                        }}
                                    >
                                        <FilePresentTwoTone sx={{ marginBottom: '-5px' }} />
                                        {folder[1].key.split('/').slice(-1)}
                                    </Paper>
                                </Button>
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
            {filePath && filePath.length > 0 && (
                <Grid item height={'95vh'} xs={12} sm={6}>
                    <Grid
                        item
                        height={'100%'}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        {filePath.toLowerCase().includes('.mp4') ? (
                            <VideoPlayer videoUrl={filePath} />
                        ) : (
                            <FileViewer filePath={filePath} />
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default FileBrowser;
