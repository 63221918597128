import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApplicationContext from '../ContextProvider';

const ForgotPassword = (): JSX.Element => {
    const [email, setEmail] = useState<string>('');
    const [popover, setPopover] = useState<string>('');
    const navigate = useNavigate();
    const context = useContext(ApplicationContext);

    const updatePopover = (popoverContent: string): void => {
        // Reset our popover after 5 seconds if it isn't manually closed
        setTimeout(() => {
            setPopover('');
        }, 5000);
        setPopover(popoverContent);
    };

    const login = async (form: React.FormEvent): Promise<void> => {
        form.preventDefault();
        context.logUserEvent({ event_type: 'user_password_reset_attempted', supplied_account: email });

        const loginResult: Response = await fetch('/users/forgot_password', {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const responseJson: any = await loginResult.json();
        if (loginResult.ok) {
            // Reset our controlled inputs to clear the form
            setEmail('');
            context.logUserEvent({
                event_type: 'user_password_reset_requested',
                user_email: email
            });
            updatePopover(`We've sent an email to the supplied password with further instructions`);
            setTimeout(() => {
                navigate('/login');
            }, 1000);
        } else {
            context.logUserEvent({ event_type: 'user_password_reset_failed', event_message: responseJson.message });
            updatePopover(`We've sent an email to the supplied password with further instructions`);
        }
    };

    return (
        <>
            <form
                onSubmit={(formData) => {
                    void login(formData);
                }}
            >
                <Grid container alignItems="center" justifyContent="center" direction="column">
                    <Grid item>
                        <h2>Request password reset email</h2>
                        <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                            <TextField
                                id="email"
                                type={'text'}
                                required
                                helperText={'Example: John@gmail.com'}
                                value={email}
                                onChange={(emailElem) => {
                                    setEmail(emailElem.target.value);
                                }}
                                variant={'outlined'}
                                label="Email"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button id="login_button" variant={'outlined'} type={'submit'}>
                            Forgot Password
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Popover
                id="informational_modal"
                open={popover.length > 1}
                anchorEl={document.getElementById('login_button')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                style={{ marginTop: '40px' }}
            >
                <Paper style={{ padding: '10px' }}>
                    {popover}
                    <IconButton
                        aria-label="close popover"
                        onClick={() => {
                            setPopover('');
                        }}
                        onMouseDown={() => {
                            setPopover('');
                        }}
                        edge="end"
                        style={{
                            display: popover.length > 1 ? 'inline-flex' : 'none',
                            marginLeft: '5px',
                            marginRight: '5px'
                        }}
                    >
                        <Close />
                    </IconButton>
                </Paper>
            </Popover>
        </>
    );
};

export default ForgotPassword;
